import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import {
    type DistributionElectricity,
    type DistributionElectricityFormInput,
    getDistributionElectricitySchema,
} from '../types'
import { convertToKiloWattHoursPrice } from '../helpers'

export function useDistributionElectricity(distributionElectricityId: string | number) {
    return useQuery({
        queryKey: ['distributionElectricity-' + distributionElectricityId],
        queryFn: async () => {
            const { data } : { data : DistributionElectricity } = await axios.get(
                '/api/distribution_electricity/single?id=' + distributionElectricityId,
            )
            return data
        },
    })
}

export const useCreateDistributionElectricity = (estateId: number | string, returnToList: ()=>void) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: DistributionElectricityFormInput) => {            
            return axios.get(`/api/distribution_electricity/create?estate_id=${estateId
            }&supplier=${data.supplier
            }&distribution_rate=${data.distribution_rate
            //}&has_manual_hdo=${data.has_manual_hdo ? 'true' : 'false'
            }&has_manual_hdo=${'true'
            }&low_price_cents=${0 // convertToKiloWattHoursPrice(data.low_price_cents)
            }&high_price_cents=${0 // convertToKiloWattHoursPrice(data.high_price_cents)
            }&signal_code=${data.signal_code
            }&ean=${data.ean}`
            )
        },
        onSuccess: () => {
            //queryClient.invalidateQueries({ queryKey: ['distributionElectricities-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['estate-' + estateId] })
            returnToList()
        },
    })
}

export const useCreateDistributionElectricity_partial = (
    estateId,
    returnToList: (data:DistributionElectricity) => void
) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: Partial<DistributionElectricityFormInput>) => {
            let url = getDistributionElectricitySchema().toUrl(
                '/api/distribution_electricity/create',
                {
                    // required fields for creation
                    low_price_cents: 0,
                    high_price_cents: 0,
                    //has_manual_hdo: true, // @fixme
                    ...data,
                },
                {
                    estate_id: estateId // we make sure id is correct
                }
            )
            return axios.get(url)
        },
        onSuccess: (response) => {
            //queryClient.invalidateQueries({ queryKey: ['distributionElectricities-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['estate-' + estateId] })
            returnToList(response.data)
        },
    })
}

export const useUpdateDistributionElectricity = (distributionElectricityId:number, handleClose: ()=>void) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: DistributionElectricityFormInput) => {
            return axios.get(`/api/distribution_electricity/update?id=${distributionElectricityId
            }&supplier=${data.supplier
            }&distribution_rate=${data.distribution_rate
            //}&has_manual_hdo=${data.has_manual_hdo ? 'true' : 'false'
            }&has_manual_hdo=${'true'
            // }&low_price_cents=${convertToKiloWattHoursPrice(data.low_price_cents)
            // }&high_price_cents=${convertToKiloWattHoursPrice(data.high_price_cents)
            }&signal_code=${data.signal_code
            }&ean=${data.ean}`
            )
        },
        onSuccess: () => {
            //queryClient.invalidateQueries({ queryKey: ['distributionElectricities-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['distributionElectricity-' + distributionElectricityId] })
            handleClose()
        },
    })
}

export const useUpdateDistributionElectricity_partial = (
    distributionElectricityId: number,
    successCallback: ((data:DistributionElectricity)=>void) | (()=>void),
) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: DistributionElectricityFormInput) => {
            let url = getDistributionElectricitySchema().toUrl(
                '/api/distribution_electricity/update',
                data,
                {
                    id: distributionElectricityId // we make sure id is correct
                }
            )
            return axios.get(url)
        },
        onSuccess: (response) => {
            //queryClient.invalidateQueries({ queryKey: ['distributionElectricities-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['distributionElectricity-' + distributionElectricityId] })
            successCallback(response.data)
        },
    })
}
