import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai/react'

import packageJson from '../../../../package.json'
import { WizardStep1 } from './steps/wizard-step1'
import type { StepName } from './types'
import { WizardStep2 } from './steps/wizard-step2'
import { WizardStep3 } from './steps/wizard-step3'
import { WizardStep4 } from './steps/wizard-step4'
import { WizardStep5 } from './steps/wizard-step5'
import { WizardStep7 } from './steps/wizard-step7'
import { WizardStep8 } from './steps/wizard-step8'
import { FONT_WEIGHT } from './components'
import { WizardStep9 } from './steps/wizard-step9'
// import { NewAppliancePlanStep } from "./steps/wizard-step10";
import { DebugWizard, useDebug } from '../debug'
import { useAppliance } from '../../hooks/use-appliance'
import { WizardStep2Prepare } from './steps/wizard-step2-prepare'
import { WizardStepWelcome } from './steps/wizard-step-welcome'
import { simplifiedAppVersionConfig } from '../../state/application'
import { useAutoSelectFirstEstateFirstAppliancePlanAndAppliances } from '../../hooks/use-simple-homepage'

export type WizardMode = 'create_estate' | 'edit_appliance'

function StepProgress(props: {
    availableSteps: StepName[]
    currentStep: StepName
}) {
    // steps are numbered from 1

    const stepNumberFromStepName = (stepName: StepName): number => {
        const currentStepAsString: string = stepName.replace(/[^0-9]+/, '')
        const currentStep = Number.parseInt(currentStepAsString)
        return currentStep
    }

    const steps = props.availableSteps.map(stepName => {
        const currentStep = stepNumberFromStepName(props.currentStep)
        const stepNumber = stepNumberFromStepName(stepName)
        return (
            <Box
                key={stepNumber}
                sx={{
                    borderRadius: '2px',
                    height: '2px',
                    backgroundColor: stepNumber <= currentStep ? '#071F3F' : '#D3D3D3',
                    flex: 1,
                }}
            />
        )
    })

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: '.5rem',
            }}
        >
            {steps}
        </Box>
    )
}

export function WizardView() {
    function numberFromParam(param: string | undefined) {
        const parsed: number = Number.parseInt(param || '', 10)
        const result: number = Number.isNaN(parsed) ? 0 : parsed
        return result
    }

    //const debug = useDebug({ name: "WizardView" })

    const navigate = useNavigate()

    // @fixme put this into jotai, so we don't need to handle url reloading; might help
    const urlParams = useParams()
    const applianceId: number = numberFromParam(urlParams.applianceId)
    const estateId: number = numberFromParam(urlParams.estateId)

    // debug.log('applianceId', applianceId, urlParams)

    const mode: WizardMode =
        urlParams.applianceId === undefined ? 'create_estate' : 'edit_appliance'

    // debug.log('mode', mode)

    return mode === 'edit_appliance' ? (
        <WizardWithEstateAndAppliance applianceId={applianceId} mode={'edit_appliance'} />
    ) : (
        <Wizard estateId={estateId} applianceId={0} mode={'create_estate'} />
    )
}

function WizardWithEstateAndAppliance({ applianceId, mode }) {
    const debug = useDebug({ name: 'WizardWithEstateAndAppliance' })

    const appliance = useAppliance(
        applianceId,
        {
            refetchOnMount: false, // @hotfix
        },
        'WizardWithEstateAndAppliance'
    )
    const { status, data, error } = appliance
    debug.logObject('appliance', appliance)

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    if (!data) {
        // should not happen
        return null
    }

    return <Wizard applianceId={applianceId} estateId={data.estate_id} mode={mode} />
}

function Wizard(props: { applianceId: number; estateId: number; mode: WizardMode }) {
    const navigate = useNavigate()

    const [currentStep, setCurrentStep] = React.useState<StepName>(
        props.mode === 'edit_appliance' ? 'step2' : 'step-welcome-0'
    )

    const goToStep = (stepName: StepName) => {
        // page will be changed so scroll to top of the screen
        window.scrollTo(0, 0)

        setCurrentStep(stepName)
    }

    function useEstateAndApplianceId() {
        const [estateId, setEstateId] = React.useState(props.estateId)
        const [applianceId, setApplianceId] = React.useState(props.applianceId)

        // "simplified app version" config will be set when estate/appliance is picked by user

        const [simplifiedConfig, setSimplifiedConfig] = useAtom(simplifiedAppVersionConfig)

        return {
            estateId,
            applianceId,
            setApplianceId: (applianceId: number) => {
                setSimplifiedConfig({
                    ...simplifiedConfig,
                    selectedApplianceId: applianceId,
                    selectedAppliancePlanId: 0,
                })
                setApplianceId(applianceId)
            },
            setEstateId: (estateId: number) => {
                setSimplifiedConfig({
                    ...simplifiedConfig,
                    selectedEstateId: estateId,
                    selectedApplianceId: 0,
                    selectedAppliancePlanId: 0,
                })
                setEstateId(estateId)
            },
        }
    }
    const { estateId, setEstateId, applianceId, setApplianceId } = useEstateAndApplianceId()

    useAutoSelectFirstEstateFirstAppliancePlanAndAppliances()

    function StepDebug({ children }) {
        return children
    }

    const AVAILABLE_STEPS: StepName[] =
        props.mode === 'create_estate'
            ? ['step-welcome-0', 'step1', 'step2', 'step3', 'step4', 'step7', 'step8', 'step9']
            : ['step-welcome-0', 'step1', 'step2', 'step3', 'step4', 'step7', 'step8', 'step9']

    return (
        <>
            <Container maxWidth="sm">
                <DebugWizard
                    applianceId={applianceId}
                    currentStep={currentStep}
                    estateId={estateId}
                    setApplianceId={setApplianceId}
                    setCurrentStep={setCurrentStep}
                    setEstateId={setEstateId}
                />

                <Box
                    sx={{
                        display: 'flex',
                        gap: '1rem',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            component={'div'}
                            sx={{
                                color: '#001F3F',
                                fontSize: '20px',
                                fontWeight: FONT_WEIGHT.medium,
                            }}
                        >
                            Průvodce nastavením
                        </Typography>
                        <IconButton
                            onClick={() => {
                                // In case Wizard is opened from bookmark ...
                                navigate('/')
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>

                    <StepProgress availableSteps={AVAILABLE_STEPS} currentStep={currentStep} />
                </Box>

                {currentStep === 'step-welcome-0' && (
                    <StepDebug>
                        <WizardStepWelcome
                            nextStep={() => {
                                goToStep('step1')
                            }}
                        />
                    </StepDebug>
                )}
                {currentStep === 'step1' && (
                    <StepDebug>
                        <WizardStep1
                            estateId={estateId}
                            setEstateId={setEstateId}
                            nextStep={() => {
                                goToStep('step2-prepare')
                            }}
                            goBack={() => {
                                goToStep('step-welcome-0')
                            }}
                        />
                    </StepDebug>
                )}
                {currentStep === 'step2-prepare' && (
                    <WizardStep2Prepare
                        estateId={estateId}
                        applianceId={applianceId}
                        setApplianceId={setApplianceId}
                        nextStep={() => goToStep('step2')}
                    />
                )}
                {currentStep === 'step2' && (
                    <StepDebug>
                        <WizardStep2
                            // setEstateId={setEstateId}
                            applianceId={applianceId}
                            estateId={estateId}
                            setApplianceId={setApplianceId}
                            goBack={() => {
                                goToStep('step1')
                            }}
                            nextStep={() => {
                                goToStep('step3')
                            }}
                        />
                    </StepDebug>
                )}
                {currentStep === 'step3' && (
                    <StepDebug>
                        <WizardStep3
                            applianceId={applianceId}
                            goBack={() => {
                                goToStep('step2')
                            }}
                            nextStep={() => {
                                goToStep('step4')
                            }}
                        />
                    </StepDebug>
                )}
                {currentStep === 'step4' && (
                    <StepDebug>
                        <WizardStep4
                            estateId={estateId}
                            goBack={() => {
                                goToStep('step3')
                            }}
                            nextStep={() => {
                                goToStep('step5')
                            }}
                        />
                    </StepDebug>
                )}
                {currentStep === 'step5' && (
                    <StepDebug>
                        <WizardStep5
                            estateId={estateId}
                            goBack={() => {
                                goToStep('step4')
                            }}
                            nextStep={() => {
                                goToStep('step7')
                            }}
                        />
                    </StepDebug>
                )}
                {currentStep === 'step7' && (
                    <StepDebug>
                        <WizardStep7
                            estateId={estateId}
                            goBack={() => {
                                goToStep('step5')
                            }}
                            nextStep={() => {
                                goToStep('step8')
                            }}
                        />
                    </StepDebug>
                )}
                {currentStep === 'step8' && (
                    <StepDebug>
                        <WizardStep8
                            applianceId={applianceId}
                            estateId={estateId}
                            goBack={() => {
                                goToStep('step7')
                            }}
                            nextStep={() => {
                                goToStep('step9')
                            }}
                        />
                    </StepDebug>
                )}
                {currentStep === 'step9' && (
                    <StepDebug>
                        <WizardStep9
                            estateId={estateId}
                            applianceId={applianceId}
                            goBack={() => {
                                goToStep('step8')
                            }}
                        />
                    </StepDebug>
                )}

                {/* 
                {currentStep === 'step10' &&
                    <StepDebug>
                        <NewAppliancePlanStep
                            estateId={estateId}
                            applianceId={applianceId}
                            goBack={() => {
                                goToStep('step9')
                            }}
                        />
                    </StepDebug>
                } */}

                <Box
                    sx={{
                        marginTop: 3,
                        fontSize: '0.75rem',
                        color: 'primary.main',
                        textAlign: 'center',
                    }}
                >
                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box>
            </Container>
        </>
    )
}
