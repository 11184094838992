import React from 'react'
import Box from '@mui/material/Box'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import MarkerIcon from '../../../../../assets/images/leaflet/marker-icon.png'

function Map({
    startingLatitude,
    startingLongitude
}: {
    startingLatitude?: number,
    startingLongitude?: number
}) {

    const mapRef = React.useRef(null)
    const leaflet = React.useRef(null)

    // https://github.com/johan/world.geo.json/blob/master/countries/CZE.geo.json?short_path=9a86b62
    const czechRepublicBoundary = {
        "type": "FeatureCollection", "features": [
            { "type": "Feature", "id": "CZE", "properties": { "name": "Czech Republic" }, "geometry": { "type": "Polygon", "coordinates": [[[16.960288, 48.596982], [16.499283, 48.785808], [16.029647, 48.733899], [15.253416, 49.039074], [14.901447, 48.964402], [14.338898, 48.555305], [13.595946, 48.877172], [13.031329, 49.307068], [12.521024, 49.547415], [12.415191, 49.969121], [12.240111, 50.266338], [12.966837, 50.484076], [13.338132, 50.733234], [14.056228, 50.926918], [14.307013, 51.117268], [14.570718, 51.002339], [15.016996, 51.106674], [15.490972, 50.78473], [16.238627, 50.697733], [16.176253, 50.422607], [16.719476, 50.215747], [16.868769, 50.473974], [17.554567, 50.362146], [17.649445, 50.049038], [18.392914, 49.988629], [18.853144, 49.49623], [18.554971, 49.495015], [18.399994, 49.315001], [18.170498, 49.271515], [18.104973, 49.043983], [17.913512, 48.996493], [17.886485, 48.903475], [17.545007, 48.800019], [17.101985, 48.816969], [16.960288, 48.596982]]] } }
        ]
    }

    React.useEffect(() => {

        if (leaflet.current) return

        // create map
        let map = L.map(mapRef.current)
        leaflet.current = map

        // set CZ boundary
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            // boundary: czechRepublicBoundary,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
        const czGeo = L.geoJSON(czechRepublicBoundary);

        // limit boundary and zoom
        map.setMaxBounds(czGeo.getBounds());
        map.setMinZoom(6)

        // map.fitBounds(czGeo.getBounds());
        // L.marker([51.5, -0.09]).addTo(map)
        // L.marker(czGeo.getBounds().getCenter()).addTo(map)
        //     .bindPopup('A pretty CSS popup.<br> Easily customizable.')
        //     .openPopup();

        if (startingLatitude !== undefined && startingLongitude !== undefined) {

            // add maker
            const startPos = L.latLng(startingLatitude, startingLongitude)
            const marker = L.marker(startPos, {
                icon: L.icon({
                    iconUrl: MarkerIcon
                })
            })
            marker.addTo(map)

            // center the map
            const offset = { x: 0, y: 0 } // x: 0, y: .0003 } // a little to the right so arrow and marker arent on top of each other
            const centerPos = L.latLng(startingLatitude + offset.x, startingLongitude + offset.y)
            map.setView(centerPos, 21)

            // marker DOM is created after .setView is called
            marker.getElement().style.filter = 'hue-rotate(161deg)' // make marker red
        } else {
            map.setView(czGeo.getBounds().getCenter(), 6)
        }
    }, []);

    return (
        <Box id='map' ref={mapRef} sx={{
            // position: relative;
            height: '300px',
            width: '100%',
        }} />
    )
}

export default Map
