import React, { useEffect } from 'react'
import { AxiosError } from 'axios'
import { Controller, useForm, type SubmitHandler } from 'react-hook-form'
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'

import CustomButton from '../../../components/custom-button'
import { AzimuthInput } from '../../../components/forms/renewable-source/components/azimuth-input'
import { DeclinationInput } from '../../../components/forms/renewable-source/components/declination-input'
import { useCreateRenewableSource, useRenewableSource, useRenewableSources, useUpdateRenewableSource } from '../../../hooks/use-renewable-source'
import type { CustomDataWithError, RenewableSource, RenewableSourceFormInput } from '../../../types'
import { useDebug } from '../../debug'
import { PreviousStepButton } from '../components'
import { BottomButtons, ErrorComponent, FormControlWithLabelWrapper, StepIcon } from './wizard-common'

export function WizardStep5({
    estateId,
    nextStep,
    goBack,
}: {
    estateId: number
    nextStep: () => void
    goBack: () => void
}) {
    const debug = useDebug({ name: 'WizardStep5' })

    const { status, data, error } = useRenewableSources(estateId)
    const [renewableSourceId, setRenewableSourceId] = React.useState<number>(0);

    const [enableEditation, setEnableEditation] = React.useState(false)

    // preselect first panel
    useEffect(() => {

        // user creates new panel -> keep that id selected
        if (renewableSourceId && data && data.findIndex(data => data.id === renewableSourceId) >= 0) {
            debug.log("Avoiding auto-selection of first id, because id is already selected")
            return
        }

        if (data && data.length >= 1) {
            debug.log("Auto-selecting first id") 
            setRenewableSourceId(data[0].id)
        }
    }, [data])

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error || data === undefined) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4rem' }}>

                <StepIcon icon={<BoltRoundedIcon />} color={'#DFF2E1'} />

                {data.length == 0 &&
                    <FormControlWithLabelWrapper
                        label={'Máte fotovoltaické panely?'}
                        id='WizardStep4-supplier'
                    >
                        <Select
                            aria-labelledby="WizardStep4-supplier"
                            value={enableEditation ? '1' : '0'}
                            onChange={(event) => {
                                const bool: boolean = event.target.value === '1' ? true : false
                                setEnableEditation(bool)
                            }}
                        >
                            {[false, true].map((bool, index) => (
                                <MenuItem
                                    value={bool ? '1' : '0'}
                                    key={index}
                                >
                                    {bool ? 'Ano' : 'Ne'}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControlWithLabelWrapper>
                }

                {
                    // multiple panels, or want's to edit one
                    (data.length > 0 || (data.length == 0 && enableEditation)) &&
                    <FormControlWithLabelWrapper
                        label={'Upravit fotovoltaický panel či přidat nový'}
                        labelId="renewable-source-name"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4rem',
                            }}
                        >
                            {
                                data && data.length > 0 ? (
                                    <RenewableSourceSelection
                                        data={data}
                                        renewableSourceId={renewableSourceId}
                                        setRenewableSourceId={setRenewableSourceId}
                                    />
                                ) : null
                            }

                            {
                                renewableSourceId ? (
                                    <Edit
                                        estateId={estateId}
                                        renewableSourceId={renewableSourceId}
                                        nextStep={nextStep}
                                        goBack={goBack}
                                    />
                                ) : (
                                    <New
                                        estateId={estateId}
                                        goBack={goBack}
                                        nextStep={nextStep}
                                        onNewCreated={(newId) => {
                                            setRenewableSourceId(newId)
                                        }}
                                    />
                                )
                            }
                        </Box>
                    </FormControlWithLabelWrapper>
                }
                <BottomButtons>
                    <PreviousStepButton
                        onClick={() => {
                            goBack()
                        }}
                    >
                        Zpět
                    </PreviousStepButton>

                    <CustomButton
                        text="Pokračovat"
                        onClick={() => {
                            nextStep()
                        }}
                    />
                </BottomButtons>
            </Box>
        </Box>
    )
}

function RenewableSourceSelection({
    data,
    renewableSourceId: applianceId,
    setRenewableSourceId,
}: {
    data: RenewableSource[] | null
    renewableSourceId: number
    setRenewableSourceId: (id: number) => void
}) {
    const debug = useDebug({ name: 'RenewableSourceSelection' })

    return (
        <>
            {data != null &&
                (
                    <Select
                        label="Fotovoltaika"
                        // id="appliance-energy-class"
                        //{...field}
                        value={applianceId}
                        onChange={event => {
                            const newId = event.target.value as number
                            setRenewableSourceId(newId)
                        }}
                    >
                        <MenuItem key={0} value={0}>
                            Vytvořit nový fotovoltaický panel ...
                        </MenuItem>
                        {(data || []) // data can be null
                            .map(renewableSource => (
                                <MenuItem key={renewableSource.id} value={renewableSource.id}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {/* <Box>Bojler {renewableSource.id}</Box>
                                        <Box>
                                            Objem: {renewableSource.blob.volume} | • Výkon{' '}
                                            {renewableSource.input_power} W
                                        </Box> */}
                                        {/* <Box sx={{
                                            paddingRight: 1,
                                            fontSize: '1.8rem',
                                            lineHeight: 0,
                                        }}>
                                            <BoltRoundedIcon fontSize='inherit'/>
                                        </Box> */}
                                        <Box>{renewableSource.name}</Box>
                                        <Box sx={{ fontSize: '0.875rem' }}>Orientace: {renewableSource.azimuth + '°'} • Sklon: {renewableSource.declination + '°'} • Výkon: {renewableSource.power + ' W'}</Box>
                                    </Box>
                                </MenuItem>
                            ))}
                    </Select>
                )}
        </>
    )
}

function New({
    estateId,
    nextStep,
    goBack,
    onNewCreated
}: {
    estateId: number
    nextStep: () => void
    goBack?: () => void
    onNewCreated: (newId: number) => void
}) {
    const debug = useDebug({ name: 'Step 5 new renewable source' })
    debug.log('estateId', estateId)

    const defaultValues: RenewableSourceFormInput = {
        azimuth: 0,
        declination: 0,
        name: '',
        power: '',
    }
    const mutation = useCreateRenewableSource(
        estateId,
        false,
        (newId) => {
            alert("Panel byl vytvořen")
            onNewCreated(newId)
            // nextStep()
        },
    )

    const onSubmit: SubmitHandler<RenewableSourceFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <Form
            estateId={estateId}
            isDisabled={mutation.isPending}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            goBack={goBack}
            buttonText={"Přidat"}
            errorMessage={mutation.isError ?
                <ErrorComponent>Došlo k chybě: {(
                    ((mutation.error as AxiosError)?.response?.data as CustomDataWithError).error
                    ||
                    mutation.error.message
                )}</ErrorComponent>
                : null
            }
        />
    )
}

function Edit({
    estateId,
    renewableSourceId,
    nextStep,
    goBack,
}: {
    estateId: number
    renewableSourceId: number
    nextStep: () => void
    goBack?: () => void
}) {
    const debug = useDebug({ name: 'Edit' })

    const renewableSource = useRenewableSource(
        renewableSourceId,
        // {
        //     refetchOnMount: false,
        // }
    )
    debug.logObject('renewable source', renewableSource)
    const { status, data, error, dataUpdatedAt } = renewableSource

    const mutation = useUpdateRenewableSource(renewableSourceId, estateId, () => {
        // nextStep()
    })

    const onSubmit: SubmitHandler<RenewableSourceFormInput> = data => {
        mutation.mutate(data)
    }

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    const defaultValues: RenewableSourceFormInput = {
        azimuth: data.azimuth ?? 0,
        declination: data.declination ?? 0,
        name: data.name ?? '',
        power: data.power ?? 0,
    }

    return (
        <Form
            estateId={estateId}
            renewableSourceId={renewableSourceId}
            goBack={goBack}
            onSubmit={onSubmit}
            isDisabled={mutation.isPending}
            defaultValues={defaultValues}
            buttonText={"Uložit"}
            errorMessage={mutation.isError ?
                <ErrorComponent>Došlo k chybě: {(
                    ((mutation.error as AxiosError)?.response?.data as CustomDataWithError).error
                    ||
                    mutation.error.message
                )}</ErrorComponent>
                : null
            }
        />
    )
}

function Form({
    estateId,
    renewableSourceId,
    defaultValues,
    onSubmit,
    isDisabled,
    goBack,
    buttonText,
    errorMessage,
}: {
    estateId: number,
    renewableSourceId?: number,
    defaultValues: RenewableSourceFormInput
    isDisabled: boolean
    onSubmit: SubmitHandler<RenewableSourceFormInput>
    goBack?: () => void
    buttonText: string
    errorMessage: React.ReactNode
}) {
    const debug = useDebug({ name: 'Form' })

    const { control, reset, watch, setValue, handleSubmit } = useForm<RenewableSourceFormInput>({
        defaultValues,
    })

    // sometimes the loading is too fast -> form is not remounted -> RHF default form values aren't changed
    useEffect(() => {
        debug.log("changing form values, because they changed by selecting another panel ID", renewableSourceId);
        reset({...defaultValues})
    },[renewableSourceId])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3rem',
                }}
            >
                {/* <Box
                    sx={{
                        fontWeight: FONT_WEIGHT.medium,
                        fontSize: '18px',
                        color: '#001F3F',
                    }}
                >
                    <Hint>Informace najdete na štítku bojleru.</Hint>
                </Box>
                */}

                <FormControlWithLabelWrapper
                    label={'Název'}
                    labelId="renewable-source-name"
                >
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => <TextField id="renewable-source-name" variant="standard" type="text" required {...field} />}
                    />
                </FormControlWithLabelWrapper>

                <FormControlWithLabelWrapper
                    label={'Výkon (W)'}
                    labelId="renewable-source-power"
                >
                    <Controller
                        name="power"
                        control={control}
                        render={({ field }) => <TextField id="renewable-source-power" variant="standard" type="number" required {...field} />}
                    />
                </FormControlWithLabelWrapper>

                <DeclinationInput
                    control={control}
                    watch={watch}
                    setValue={setValue}
                />

                <AzimuthInput
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    estateId={estateId} // numberOrUndefinedFromString(estateId)}
                />

                {errorMessage}

                <BottomButtons bottomPadding={false}>
                    <Box />

                    <CustomButton
                        text={buttonText}
                        type="submit"
                        disabled={isDisabled}
                    />
                </BottomButtons>
            </Box>
        </form>
    )
}
