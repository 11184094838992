import { useAtom } from 'jotai/react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { simplifiedAppVersion, simplifiedAppVersionConfig } from '../state/application'
import { useAppliancePlans } from './use-appliance-plan'
import { useDebug } from '../views/debug'
import { useCreateEstate, useEstates } from './use-estate'
import { useAppliances } from './use-appliance'

export function useRedirectToSimpleHomepageIfEnabled() {
    const [simplifiedAppVersionValue, setSimplifiedAppVersion] = useAtom(simplifiedAppVersion)
    const navigate = useNavigate()
    if (simplifiedAppVersionValue) {
        navigate('/', {
            replace: true,
        })
    }
}

export const useAutoSelectFirstEstateFirstAppliancePlanAndAppliances = () => {
    const debug = useDebug({ name: 'simple homepage - auto select estate, appliance and appliance plan' })

    const [simplifiedConfig, setSimplifiedConfig] = useAtom(simplifiedAppVersionConfig)

    const estates = useEstates()
    const appliances = useAppliances(simplifiedConfig.selectedEstateId)
    const appliancePlans = useAppliancePlans(simplifiedConfig.selectedApplianceId)

    useEffect(() => {
        debug.log('estates changed data ...')

        // do not override if estate is selected

        if (simplifiedConfig.selectedEstateId) return

        if (estates.status === 'success' && estates.data.length) {
            debug.log(
                `estates changed data ... -> select first estates ${estates.data[0].id}`
            )

            setSimplifiedConfig({
                ...simplifiedConfig,
                selectedEstateId: estates.data[0].id, // this will trigger loading of appliance plans
                selectedApplianceId: 0,
                selectedAppliancePlanId: 0,
            })
        }
    }, [estates.data])

    useEffect(() => {
        debug.log('appliances changed data ...')

        // do not override if appliance is selected

        if (simplifiedConfig.selectedApplianceId) return

        if (appliances.status === 'success' && appliances.data.length) {
            debug.log(
                `appliances changed data ... -> select first appliance ${appliances.data[0].id}`
            )

            setSimplifiedConfig({
                ...simplifiedConfig,
                selectedApplianceId: appliances.data[0].id, // this will trigger loading of appliance plans
                selectedAppliancePlanId: 0,
            })
        }
    }, [appliances.data])

    useEffect(() => {
        debug.log('appliance plan changed data ...')

        // there is only one appliance plane so select it
        if (appliancePlans.status === 'success' && appliancePlans.data.length) {
            debug.log(
                `appliance plan changed data ... -> selected appliance plan ${appliancePlans.data[0].id}`
            )

            setSimplifiedConfig({
                ...simplifiedConfig,
                selectedAppliancePlanId: appliancePlans.data[0].id,
            })
        }
    }, [appliancePlans.data])
}
