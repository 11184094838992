import axios from 'axios'
import { AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useNavigate } from 'react-router'

import type { EstateFormInput, Estate, EstateGPS, SavedAmountPerMonth, EstateIsReady, OptimalizationIsActive } from '../types'
import { useAtom } from 'jotai'
import { selectedEstateIdAtom } from '../state/application'
import { useDebug } from '../views/debug'
import { useEffect } from 'react'

export function useEstates() {
    return useQuery({
        queryKey: ['estates'],
        queryFn: async () => {
            const { data } = await axios.get(
                '/api/estate/list',
            )
            return data
        },
    })
}

export function useEstate(estateId:number) {
    return useQuery< Estate, AxiosError >({
        queryKey: ['estate-' + estateId],
        queryFn: async () => {
            const { data }: { data: Estate }  = await axios.get(
                '/api/estate/single?id=' + encodeURIComponent(estateId),
            )
            return data
        },
    })
}

export function useIsReady(estateId) {
    return useQuery<EstateIsReady, AxiosError>({
        queryKey: ['isReady-' + estateId],
        queryFn: async () => {
            const { data } = await axios.get('/api/estate/is_ready?id=' + estateId)
            return data
        },
    })
}

export function usePrepareOptimisation(estateId, setResponse) {
    const queryClient = useQueryClient()
    
    return useMutation({
        mutationFn: () => {
            return axios.get(
                '/api/estate/prepare_optimalization?id=' + estateId,
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['isOptimised-' + estateId] })
            if (response.data?.error) {
                setResponse(response.data.error)
            } else {
                setResponse('Úspěšně započato')
            }
        },
        onError: response => {
            queryClient.invalidateQueries({ queryKey: ['isOptimised-' + estateId] })
            if (response instanceof AxiosError && response.response?.data?.error) {
                setResponse(response.response?.data?.error)
            } else {
                setResponse('Nepovedlo se zahájit vytopení')
            }
        },
    })
}

export function useForceOptimisation(estateId, setResponse) {
    const queryClient = useQueryClient()
    
    return useMutation({
        mutationFn: () => {
            return axios.get(
                '/api/estate/force_optimalization?id=' + estateId,
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['forceOptimisation-' + estateId] })
            if (response.data?.error) {
                setResponse(response.data.error)
            } else {
                setResponse('Aktualizace úspěšná')
            }
        },
        onError: response => {
            queryClient.invalidateQueries({ queryKey: ['forceOptimisation-' + estateId] })
            if (response instanceof AxiosError && response.response?.data?.error) {
                setResponse(response.response?.data?.error)
            } else {
                setResponse('Aktualizace neúspěšná')
            }
        },
    })
}

export function useToggleOptimisation(estateId) {
    const queryClient = useQueryClient()
    
    return useMutation({
        mutationFn: (activate: boolean) => {
            return axios.get(
                `/api/estate/activate_optimalization?id=${estateId}&activate=${activate}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['estate-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['estates'] })
            queryClient.invalidateQueries({ queryKey: ['isOptimised-' + estateId] })
        },
    })
}

export function useIsOptimised(estateId) {
    return useQuery<OptimalizationIsActive, AxiosError>({
        queryKey: ['isOptimised-' + estateId],
        queryFn: async () => {
            const { data } = await axios.get('/api/estate/optimalization_is_active?id=' + estateId)
            return data
        },
    })
}

export function useSavedAmount(estateId) {
    return useQuery({
        queryKey: ['savedAmount-' + estateId],
        queryFn: async () => {
            const { data } = await axios.get('/api/estate/saved_amount_cents?id=' + estateId)
            return data
        },
    })
}

export function useSavedAmountPerMonth(estateId) {
    return useQuery<SavedAmountPerMonth, AxiosError>({
        queryKey: ['savedAmountPerMonth-' + estateId],
        queryFn: async () => {
            const { data } = await axios.get(
                '/api/estate/saved_amount_cents_per_month?id=' + encodeURIComponent(estateId)
            )
            return data
        },
    })
}

export const useCreateEstate = (
    redirect:boolean = true,
    onSuccess?: (_: Estate) => void
) => {
    const queryClient = useQueryClient()

    const navigate = useNavigate()

    return useMutation({
        mutationFn: (data: EstateFormInput) => {
            let url = `/api/estate/create?`;
            
            let params: string[] = []
            if( data.name !== undefined ) {
                params.push(`name=${encodeURIComponent(data.name)}`)
            }
            if( data.adress !== undefined ) {
                params.push(`adress=${encodeURIComponent(data.adress)}`)
            }
            url += params.join('&')

            url += `&latitude=${0}&longitude=${0}` // required fields by backend

            return axios.get(url)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['estates'] })
            if(onSuccess) {
                onSuccess(response.data)
            }
            if (redirect && response.data.id) {
                navigate('/estate/' + response.data.id)
            }
        },
    })
}

export const useUpdateEstate = (estateId:number, resetDrawer: ()=>void) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: Partial<EstateFormInput>) => {
            let url = `/api/estate/update?id=${encodeURIComponent(estateId)}`;
            if( data.name !== undefined ) {
                url += `&name=${encodeURIComponent(data.name)}`
            }
            if( data.adress !== undefined ) {
                url += `&adress=${encodeURIComponent(data.adress)}`
            }
            // &latitude=${data.latitude}&longitude=${data.longitude} on backend

            return axios.get(url)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['estates'] })
            queryClient.invalidateQueries({ queryKey: ['estate-' + estateId] })
            resetDrawer()
        },
    })
}

export const useDeleteEstate = estateId => {
    const queryClient = useQueryClient()
    
    const navigate = useNavigate()

    const [selectedEstateId, setSelectedEstateId] = useAtom(selectedEstateIdAtom)

    return useMutation({
        mutationFn: () => {
            return axios.get(`/api/estate/delete?id=${estateId}`)
        },
        onSuccess: () => {
            setSelectedEstateId(null)
            queryClient.invalidateQueries({ queryKey: ['estates'] })
            navigate('/')
        },
    })
}

