import axios, { AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import type { Appliance, ApplianceFormInput } from '../types'
import { useNavigate } from 'react-router'
import { debugValue } from "../views/debug";
import { getApplianceSchema } from "../types";

export function useAppliances(estateId) {
    return useQuery<Appliance[], AxiosError>(
        debugValue('debugValue', // @debug
        ({
            queryKey: ['appliances-' + estateId],
            queryFn: async () => {
                const { data } : { data : Array<Appliance> } = await axios.get(
                    '/api/appliance/list?estate_id=' + estateId,
                )

                return data ? data.sort((a,b)=> a.id - b.id): data // backend doesn't sort the data
            },
        })
    ,estateId)) // @debug
}

export function useAppliance(applianceId, queryOptions?: Partial<Parameters<typeof useQuery>[0]>, debugId?: string) {
    return useQuery<Appliance, AxiosError>(
        debugValue( 'useAppliance', // @debug
            ({ queryKey: ['appliance-' + applianceId],
                queryFn: async () => {
                    const { data } : { data : Appliance } = await axios.get(
                        '/api/appliance/single?id=' + applianceId,
                    )
                    return data
                },
                ...queryOptions
            })
            ,applianceId, debugId)  // @debug
    )
}

export const useCreateAppliance = (
    estateId, 
    redirect: boolean = true, 
    onSuccess?: (applianceId: number) => void,
    create_basic_consumption: boolean = false, // backend will initialize also related basic consumption
) => {
    const queryClient = useQueryClient()

    const navigate = useNavigate()
    
    return useMutation({
        mutationFn: (data: ApplianceFormInput) => {
            const blob = {
                "volume": typeof data.volume === 'number' ? data.volume : parseFloat(data.volume),
                "room_temperature": typeof data.roomTemperature === 'number' ? data.roomTemperature : parseFloat(data.roomTemperature),
                // "water_source_temperature": typeof data.waterSourceTemperature === 'number' ? data.waterSourceTemperature : parseFloat(data.waterSourceTemperature), // value is set by backend (#158)
                //"hot_water_min_temperature": typeof data.hotWaterMinTemperature === 'number' ? data.hotWaterMinTemperature : parseFloat(data.hotWaterMinTemperature),
                "hot_water_min_temperature": 40,
                "hot_water_max_temperature": typeof data.hotWaterMaxTemperature === 'number' ? data.hotWaterMaxTemperature : parseFloat(data.hotWaterMaxTemperature),
                "energetic_class": data.energyClass,
                //"dissipation": typeof data.dissipation === 'number' ? data.dissipation : parseFloat(data.dissipation),
                //"price_weight": typeof data.priceWeight === 'number' ? data.priceWeight : parseFloat(data.priceWeight),
                //"temperature_weight": typeof data.temperatureWeight === 'number' ? data.temperatureWeight : parseFloat(data.temperatureWeight),
            }
            
            return axios.get(`/api/appliance/create?estate_id=${estateId
                }&type=Appliance::Boiler&input_power=${data.inputPower
                //}&loss_power=${data.lossPower
                //}&energy_capacity=${data.energyCapacity
                //}&is_dependent_on_hdo=${data.isDependentOnHdo
                }&is_dependent_on_hdo=${'true'
                }&blob=${JSON.stringify(blob)
                }${create_basic_consumption? '&create_basic_consumption=true' : ''
                }`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['appliances-' + estateId] })

            if(onSuccess) onSuccess(response.data.id)

            if (redirect && response.data.id) {
                navigate(`/estate/${estateId}/appliance/${response.data.id}`)
            }
        },
    })
}

export const useUpdateAppliance = (applianceId, estateId, resetDrawer) => {
    const queryClient = useQueryClient()
    
    return useMutation({
        mutationFn: (data: ApplianceFormInput) => {
            const blob = {
                "volume": typeof data.volume === 'number' ? data.volume : parseFloat(data.volume),
                "room_temperature": typeof data.roomTemperature === 'number' ? data.roomTemperature : parseFloat(data.roomTemperature),
                // "water_source_temperature": typeof data.waterSourceTemperature === 'number' ? data.waterSourceTemperature : parseFloat(data.waterSourceTemperature), // backend set's this value
                //"hot_water_min_temperature": typeof data.hotWaterMinTemperature === 'number' ? data.hotWaterMinTemperature : parseFloat(data.hotWaterMinTemperature),
                "hot_water_min_temperature": 40,
                "hot_water_max_temperature": typeof data.hotWaterMaxTemperature === 'number' ? data.hotWaterMaxTemperature : parseFloat(data.hotWaterMaxTemperature),
                "energetic_class": data.energyClass,
                //"dissipation": typeof data.dissipation === 'number' ? data.dissipation : parseFloat(data.dissipation),
                //"price_weight": typeof data.priceWeight === 'number' ? data.priceWeight : parseFloat(data.priceWeight),
                //"temperature_weight": typeof data.temperatureWeight === 'number' ? data.temperatureWeight : parseFloat(data.temperatureWeight),
            }
            
            return axios.get(`/api/appliance/update?id=${applianceId
                }&estate_id=${estateId
                }&type=Appliance::Boiler&input_power=${data.inputPower
                //}&loss_power=${data.lossPower
                //}&energy_capacity=${data.energyCapacity
                //}&is_dependent_on_hdo=${data.isDependentOnHdo
                }&is_dependent_on_hdo=${'true'
                }&blob=${JSON.stringify(blob)}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appliances-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['appliance-' + applianceId] })
            resetDrawer()
        },
    })
}

// @todo use this instead of other appliance update - if there is no logic or filtering inside
// So we can do update only 1 field. And to remove mutation from needing to sychronize RHF,type and mutation.
export const useUpdateAppliance_partial = (applianceId, estateId, resetDrawer) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: Partial<ApplianceFormInput>) => {

            let url = getApplianceSchema().toUrl(
                '/api/appliance/update',
                data,
                {
                    id: applianceId // we make sure id is correct
                }
            )
            return axios.get(url)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appliances-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['appliance-' + applianceId] })
            resetDrawer()
        },
    })
}

export const useDeleteAppliance = (applianceId, estateId) => {
    const queryClient = useQueryClient()

    const navigate = useNavigate()

    return useMutation({
        mutationFn: () => {
            return axios.get(`/api/appliance/delete?id=${applianceId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appliances-' + estateId] })
            navigate(`/estate/${estateId}`)
        },
    })
}
